import PaidIcon from '@mui/icons-material/Paid';
import { Avatar, Card, CardContent, CardHeader } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom'
import MEDrawer from '../../components/MEDrawer';
import useAxios from '../../hooks/useAxios';
import { green, grey } from '@mui/material/colors';
import useLocalStorage from '../../hooks/useLocalStorage';

const AccountHeadLedger = () => {
    const { t } = useTranslation();

    const { id } = useParams();
    const [accountHeadLedger, setAccountHeadLedger] = useState([]);
    const [ledgerType, setLedgerType] = useState('cash');
    const [user, setUser] = useLocalStorage("user", null);
    const [accountHeadInfo, setAccountHeadInfo] = useState({});
    const [listError, sendListRequest] = useAxios(setData);

    function setData(data) {
        setAccountHeadInfo(data.data.party);
        setAccountHeadLedger(data.data.ledger);
        if (data.data.accountHead.headName === 'Buy' || data.data.accountHead.headName === 'Sell') {
            setLedgerType('buy_sell');
        } else {
            setLedgerType(data.data.accountHead.headName);   
        }
    }

    useEffect(() => {
        sendListRequest({
            url: `/accountHead/${id}`,
            method: 'GET',
            body: {},
            headers: { Accept: 'application/json', Authorization: 'Bearer ' + user.access_token }
        })
    },[id]);

    const columns = [
        { field: 'id', headerName: t('id') , width: 50},
        { field: 'transactionDate', headerName: t('date') , width: 120},
        { field: 'headName', headerName: t('account') , width: 200},
        { field: 'debitInPKR', headerName: t('debit_PKR') , width: 150, valueGetter: (params) => `${new Intl.NumberFormat('en-IR').format(params.row.debitInPKR)}`},
        { field: 'creditInPKR', headerName: t('credit_PKR') , width: 150, valueGetter: (params) => `${new Intl.NumberFormat('en-IR').format(params.row.creditInPKR)}`},
        { field: 'balanceInPKR', headerName: t('balance_PKR') , width: 150 , valueGetter: (params) => `${new Intl.NumberFormat('en-IR').format(params.row.balanceInPKR)}`},
        { field: 'debitInTMN', headerName: t('debit_TMN') , width: 150, valueGetter: (params) => `${new Intl.NumberFormat('en-IR').format(params.row.debitInTMN)}`},
        { field: 'creditInTMN', headerName: t('credit_TMN') , width: 150, valueGetter: (params) => `${new Intl.NumberFormat('en-IR').format(params.row.creditInTMN)}`},
        { field: 'balanceInTMN', headerName: t('balance_TMN') , width: 150, valueGetter: (params) => `${new Intl.NumberFormat('en-IR').format(params.row.balanceInTMN)}`},
        { field: 'description', headerName: t('description') , width: 300},
    ];

    return (
        <MEDrawer>
            <Card sx={{ bgcolor: grey[100]}}>
                <CardHeader avatar={<Avatar sx={{ bgcolor: green[500] }}><PaidIcon  /></Avatar>} title={t(ledgerType) + ' ' + t('account') + ' ' + t('ledger')} />
                <CardContent sx={{ width: '100%' }}>
                    <DataGrid 
                        density="compact" 
                        autoHeight 
                        rows={accountHeadLedger} 
                        columns={columns} 
                        pageSize={50} 
                        rowsPerPageOptions={[50]}
                        getCellClassName={(params) => {
                            if (params.value && params.value.toString().substring(0,1) === '-') {
                                return 'minus';
                            }
                            return '';
                        }}
                    />
                </CardContent>
            </Card>
        </MEDrawer>
    )
}

export default AccountHeadLedger

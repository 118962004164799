import PaidIcon from '@mui/icons-material/Paid';
import { Avatar, Card, CardContent, CardHeader } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom'
import MEDrawer from '../../components/MEDrawer';
import useAxios from '../../hooks/useAxios';
import { green, grey } from '@mui/material/colors';
import useLocalStorage from '../../hooks/useLocalStorage';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const PartyLedger = () => {
    const { t } = useTranslation();

    const { id } = useParams();
    const [partyLedger, setPartyLedger] = useState([]);
    const [isRequesting, setIsRequesting] = useState(false);
    const [user, setUser] = useLocalStorage("user", null);
    const [partyInfo, setPartyInfo] = useState({});
    const [listError, sendListRequest] = useAxios(setData);
    const [markOKError, sendMarkOKRequest] = useAxios(setMarkOK);

    function setData(data) {
        setPartyInfo(data.data.party);
        setPartyLedger(data.data.ledger);
    }

    function setMarkOK(data) {
        setIsRequesting(false);
        window.location.reload(true);
    }

    const handleMarkOK = useCallback(
        (transactionID) => () => {
        if (transactionID === 0) {
            alert('You cannot mark this OK.');
            return;
        }
          if (window.confirm('Are you sure you want to mark this record OK?')) {
            if (isRequesting) return;
            setIsRequesting(true);
            sendMarkOKRequest({
              url: `/party/${id}/${transactionID}`,
              method: 'POST',
              body: {},
              headers: { Accept: 'application/json', Authorization: 'Bearer ' + user.access_token }
            });
          }
        }, []
    );

    useEffect(() => {
        sendListRequest({
            url: `/party/${id}?isShowAll=0`,
            method: 'GET',
            body: {},
            headers: { Accept: 'application/json', Authorization: 'Bearer ' + user.access_token }
        })
    },[]);

    const columns = [
        { field: 'id', headerName: t('id') , width: 50},
        { field: 'transactionDate', headerName: t('date') , width: 120},
        { field: 'description', headerName: t('description') , width: 300},
        { field: 'headName', headerName: t('account') , width: 200},
        { field: 'transactionTypeNumber', headerName: t('ref_no') , width: 80, valueGetter: (params) => params.row.trans.length && `${params.row.trans[0]?.transactionTypeNumber}`},
        { field: 'debitInPKR', headerName: t('debit_PKR') , width: 150, valueGetter: (params) => `${new Intl.NumberFormat('en-IR').format(params.row.debitInPKR)}`},
        { field: 'creditInPKR', headerName: t('credit_PKR') , width: 150, valueGetter: (params) => `${new Intl.NumberFormat('en-IR').format(params.row.creditInPKR)}`},
        { field: 'balanceInPKR', headerName: t('balance_PKR') , width: 150 , valueGetter: (params) => `${new Intl.NumberFormat('en-IR').format(params.row.balanceInPKR)}`},
        { field: 'debitInTMN', headerName: t('debit_TMN') , width: 150, valueGetter: (params) => `${new Intl.NumberFormat('en-IR').format(params.row.debitInTMN)}`},
        { field: 'creditInTMN', headerName: t('credit_TMN') , width: 150, valueGetter: (params) => `${new Intl.NumberFormat('en-IR').format(params.row.creditInTMN)}`},
        { field: 'balanceInTMN', headerName: t('balance_TMN') , width: 150, valueGetter: (params) => `${new Intl.NumberFormat('en-IR').format(params.row.balanceInTMN)}`},
        {
            field: 'Action', headerName: t('action'), width: 200, type: 'actions', getActions: (params, GridRowParams) => [
                <GridActionsCellItem icon={<CheckCircleIcon />} onClick={handleMarkOK(params.id)} label={t('mark_ok')} />,
            ]
        }
    ];

    return (
        <MEDrawer>
            <Card sx={{ bgcolor: grey[100]}}>
                <CardHeader avatar={<Avatar sx={{ bgcolor: green[500] }}><PaidIcon  /></Avatar>} title={t('party') + ' ' + t('ledger')} />
                <CardContent sx={{ width: '100%' }}>
                    <DataGrid 
                        density="compact" 
                        autoHeight 
                        rows={partyLedger} 
                        columns={columns} 
                        pageSize={50} 
                        rowsPerPageOptions={[50]} 
                        getCellClassName={(params) => {
                            var classNames = '';
                            if (params.value && params.value.toString().substring(0, 1) === '-') {
                                classNames = 'minus';
                            }
                            if (params.value && params.value.toString().substring(0, 5) === 'Total') {
                                classNames += ' total-row';
                            }
                            if (params.value && params.value.toString().substring(0, 17) === 'Previous Balance') {
                                classNames += ' prev-balance-row';
                            }
                            return classNames;
                        }}
                    />
                </CardContent>
            </Card>
        </MEDrawer>
    )
}

export default PartyLedger

import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, FormHelperText } from '@mui/material'
import { useState, useEffect, useCallback } from 'react';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import MEDrawer from '../../components/MEDrawer';
import Toast from '../../components/Toast';
import useAxios from '../../hooks/useAxios';
import useLocalStorage from '../../hooks/useLocalStorage';
import useToast from '../../hooks/useToast';
import Avatar from '@mui/material/Avatar';
import { Card, CardContent, CardHeader } from '@mui/material';
import PaidIcon from '@mui/icons-material/Paid';
import ListIcon from '@mui/icons-material/List';
import { blue, green, grey } from '@mui/material/colors';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';

const Currency = () => {
  const initialState = {currencyName: '', currencyCode: '', currencySymbol: '', exchangeRate: '1', convertToPKROperator: '', convertFromPKROperator: ''};
  const [formValues, setFormValues] = useState(initialState);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [currencyData, setCurrencyData] = useState([]);
  const [buttonText, setButtonText] = useState('save');
  const [editID, setEditID] = useState(0);
  const [isRequesting, setIsRequesting] = useState(false);
  const { t, i18n } = useTranslation();

  const [listError, sendListRequest] = useAxios(setData);
  const [editError, sendEditRequest] = useAxios(setEditData);
  const [deleteError, sendDeleteRequest] = useAxios(checkDeleted);

  const [error, sendPostRequest] = useAxios(getSavedData);

  const [user, setUser] = useLocalStorage("user", null);
  
  const { open, setOpen, responseType, setResponseType, responseMessage, setResponseMessage, handleClose } = useToast();

  function setData(data) {
    setCurrencyData(data.data);
  }

  function setEditData(data) {
    setFormValues(data.data.data);
    setIsRequesting(false);
  }

  function checkDeleted(data) {
    if (data.data.success === true) {
      setResponseType('success');
      setResponseMessage(data.data.message);
    } else {
      setResponseType('error');
      setResponseMessage(data.message);
    }
    setOpen(true);
    fetchListData();
    setIsRequesting(false);
  }

  const validate = (values) => {
    const errors = {};

    if (!values.currencyName.length) {
      errors.currencyName = t('currency') + ' ' + t('name') + ' ' + t('is_required');
    }
    if (!values.currencyCode.length) {
      errors.currencyCode = t('currency') + ' ' + t('code') + ' ' + t('is_required');
    }
    if (!values.currencySymbol.length) {
      errors.currencySymbol = t('currency') + ' ' + t('symbol') + ' ' + t('is_required');
    }
    if (!values.exchangeRate.length) {
      errors.exchangeRate = t('exchange') + ' ' + t('rate') + ' ' + t('is_required');
    }
    if (!values.convertToPKROperator.length) {
      errors.convertToPKROperator = "To PKR Converter is required";
    }
    if (!values.convertFromPKROperator.length) {
      errors.convertFromPKROperator = "From PKR Converter is required";
    }
    return errors;
  }

  function getSavedData(data) {
    console.log(data);
    if (data.status === 200) {
      setResponseType('success');
      setResponseMessage(data.data.message);
      setFormValues(initialState);
    } else {
      setResponseType('error');
      setResponseMessage(data.data.message);
    }
    setOpen(true);
    fetchListData();
    setButtonText('save');
    setEditID(0);
    setIsRequesting(false);
  }

  const handleEdit = useCallback(
    (id) => () => {
      // navigate(`/currency/${id}`);
      if (isRequesting) return;
      setIsRequesting(true);
      setButtonText('update');
      setEditID(id);
      sendEditRequest({
        url: '/currency/'+id,
        method: 'GET',
        body: null,
        headers: { Accept: 'application/json', Authorization: 'Bearer ' + user.access_token }
      });
    },[]
  );


  const handleDelete = useCallback(
    (id) => () => {
      if (window.confirm('Are you sure you want to delete this record?')) {
        if (isRequesting) return;
        setIsRequesting(true);
        sendDeleteRequest({
          url: '/currency/'+id,
          method: 'DELETE',
          body: {},
          headers: { Accept: 'application/json', Authorization: 'Bearer ' + user.access_token }
        });
      }
    },[]
  );

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({...formValues, [name] : value});
    console.log(formValues);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  const handleCancel = () => {
    setFormValues(initialState);
    setButtonText('save');
    setEditID(0);
    setIsSubmit(false);
    setFormErrors({});
  }

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      if (isRequesting) return;
      setIsRequesting(true);
      if (!isNaN(parseInt(editID)) && editID > 0) {
        sendPostRequest({
          url: '/currency/'+editID,
          method: 'PUT',
          body: formValues,
          headers: { Accept: 'application/json', Authorization: 'Bearer ' + user.access_token }
        });
      } else {
        sendPostRequest({
          url: '/currency',
          method: 'POST',
          body: formValues,
          headers: { Accept: 'application/json', Authorization: 'Bearer ' + user.access_token }
        });
      }      
    }
  },[formErrors]);

  useEffect(() => {
    fetchListData();
  },[]);

  function fetchListData() {
    sendListRequest({
      url: '/currency',
      method: 'GET',
      body: {},
      headers: { Accept: 'application/json', Authorization: 'Bearer ' + user.access_token }
    })
  }

  const columns = [
    { field: 'currencyID', headerName: t('id'), width: 100},
    { field: 'currencyName', headerName: t('currency_name'), width: 500, valueGetter: (params) => `${params.row.currencyName} (${params.row.currencySymbol})`},
    { field: 'dateCreated', headerName: t('date_created'), width: 300},
    { field: 'Action', headerName: t('action'), width: 300, type: 'actions', getActions: (params, GridRowParams) => [
      <GridActionsCellItem icon={<DeleteIcon />} onClick={handleDelete(params.id)} label= { t('delete')} />,
      <GridActionsCellItem icon={<EditIcon />} onClick={handleEdit(params.id)} label= { t('edit')} />
    ]}
  ];

  return (
    <MEDrawer>
        <Box>
          <Toast open={open} handleClose={handleClose} responseType={responseType} responseMessage={responseMessage} />
          <div>
          <Card sx={{ bgcolor: blue[50]}}>
          <CardHeader 
            avatar={
              <Avatar sx={{ bgcolor: green[500] }}><PaidIcon  /></Avatar>
            }
            title={t('currency') + ' ' + t('form')} />
          <CardContent sx={{ width: '100%' }}>
              <TextField sx={{ ml: 1 }} helperText={formErrors.currencyName} name="currencyName" required variant="outlined" label={ t('currency_name') } value={formValues.currencyName} onChange={handleChange} size="small" error={formErrors?.currencyName} />
              <TextField sx={{ ml: 1 }} helperText={formErrors.currencyCode} name="currencyCode" required variant="outlined" label={ t('currency_code') } value={formValues.currencyCode} onChange={handleChange} size="small" error={formErrors?.currencyCode} />
              <TextField sx={{ ml: 1 }} helperText={formErrors.currencySymbol} name="currencySymbol" required variant="outlined" label={ t('currency_symbol') } value={formValues.currencySymbol} onChange={handleChange} size="small" error={formErrors?.currencySymbol} />
              <TextField sx={{ ml: 1 }} helperText={formErrors.exchangeRate} name="exchangeRate" required variant="outlined" label={ t('exchange') + ' ' + t('rate') } value={formValues.exchangeRate} onChange={handleChange} size="small" error={formErrors?.exchangeRate} />
              <FormControl size="small" sx={{ ml: 1, minWidth: 200  }} error={formErrors?.convertToPKROperator} >
                  <InputLabel>{ t('to_pkr_operator') }: </InputLabel>
                  <Select label={ t('to_pkr_operator') } onChange={handleChange} name="convertToPKROperator" value={formValues.convertToPKROperator} required>
                  <MenuItem value="">---</MenuItem>
                  <MenuItem value="*">{ t('multiply') } (x)</MenuItem>
                  <MenuItem value="/">{ t('divide') }  (/)</MenuItem>
                  </Select>
                  <FormHelperText>{formErrors.convertToPKROperator}</FormHelperText>
              </FormControl>
              <FormControl size="small" sx={{ ml: 1, minWidth: 200 }}  error={formErrors?.convertFromPKROperator}>
                  <InputLabel>{ t('from_pkr_operator') }: </InputLabel>
                  <Select label={ t('from_pkr_operator') } onChange={handleChange} name="convertFromPKROperator" value={formValues.convertFromPKROperator} required>
                  <MenuItem value="">---</MenuItem>
                  <MenuItem value="*">{ t('multiply') } (x)</MenuItem>
                  <MenuItem value="/">{ t('divide') }  (/)</MenuItem>
                  </Select>
                  <FormHelperText>{formErrors.convertFromPKROperator}</FormHelperText>
              </FormControl>
              <br />
              <br />
              <Button sx={{ ml: 1 }} variant="contained" onClick={handleSubmit}>{ t(buttonText) }</Button>
              <Button sx={{ ml: 2 }} variant="contained" color="secondary" onClick={handleCancel}> {t('cancel')} </Button>
              </CardContent>
        </Card>
          </div>
        </Box>
        <br />
        <Card sx={{ bgcolor: grey[100]}}>
          <CardHeader 
            avatar={
              <Avatar sx={{ bgcolor: green[500] }}><ListIcon  /></Avatar>
            }
            title={ t('currency') + ' ' + t('list') } />
          <CardContent sx={{ width: '100%' }}>
            <DataGrid 
              density="compact"
              autoHeight
              rows={currencyData}
              columns={columns}
              pageSize={50}
              rowsPerPageOptions={[50]}
              checkboxSelection
              getRowId={row => row.currencyID}
            />
          </CardContent>
        </Card>
    </MEDrawer>
  )
}

export default Currency;

import { Button } from '@mui/material';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import useAxios from '../../hooks/useAxios';
import useLocalStorage from '../../hooks/useLocalStorage';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

var ctr = 0;
const DailyCashReport = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const reportDate = searchParams.get('reportDate');
    const [currencies, setCurrencies] = useState([]);
    const [currencyTotal, setCurrencyTotal] = useState({});
    const [transactionData, setTransactionData] = useState([]);
    const [user, setUser] = useLocalStorage("user", null);
    const [listError, sendListRequest] = useAxios(setData);
    const [isShowTotal, setIsShowTotal] = useState(false);

    const { t, i18n } = useTranslation();

    function setData(data) {
        setCurrencyTotal(data.data.currencyObj);
        setCurrencies(data.data.currencies);
        setTransactionData(data.data.transactions);

        if (ctr == 0) {
            data.data.currencies.map((currency) => {
                currencyTotal[currency.currencyCode] = 0;
            })
            data.data.transactions.map((transaction,index) => {
                data.data.currencies.map((currency) => {
                    if (currencyTotal.hasOwnProperty(currency.currencyCode)) {
                        if (transaction.currencyID == currency.currencyID) {
                            if (transaction.transactionTypeID == 1) {
                                currencyTotal[currency.currencyCode] -= transaction.amount;
                            } else {
                                currencyTotal[currency.currencyCode] += transaction.amount;
                            }
                        }
                    } else {
                        currencyTotal[currency.currencyCode] = 0;
                    }
                })
    
                if (data.data.transactions.length == (index+1)) {
                    setIsShowTotal(true);
                }
            });
        }
        
        ctr++;
    }

    const handlePrintPDF = () => {
        // const doc = new jsPDF();
        // autoTable(doc, { html: '#daily-cash-report' })
        // doc.save('dailyCashBook.pdf');
        window.print();
    }

    function fetchListData() {
        sendListRequest({
          url: '/accountHead/index?transactionDate='+reportDate,
          method: 'GET',
          body: {},
          headers: { Accept: 'application/json', Authorization: 'Bearer ' + user.access_token }
        })
    }

    useEffect(() => {
        fetchListData();
        document.documentElement.lang = 'ur';
        document.documentElement.dir = 'rtl';
        i18n.changeLanguage('ur');
    },[]);
    
    return (
        <div className="report">
            <Button type="button" onClick={ handlePrintPDF }>Print</Button>
            <h1 style={{ textAlign: 'center' }}>{ t('daily_cash_book') } - { reportDate } </h1>
            <table cellPadding="5" id="daily-cash-report" style={{ width: '100%', borderCollapse: 'collapse' }} border="1">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>{ t('transaction_type') }</th>
                        <th>{ t('account') }</th>
                        <th>{ t('detail') }</th>
                        <th>{ t('ref_no') }</th>
                        <th>{ t('description') }</th>
                        {
                            currencies?.map((currency) => <th key={currency.currencyID}>{ t(currency.currencyName) }</th>)
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        transactionData.length && transactionData.map((transaction,index) => {
                            return (
                                <tr key={transaction.transactionID}>
                                    <td>{ index+1} </td>
                                    <td>{ t(transaction.transactionType.toLowerCase().slice(0, -1)) }</td>
                                    <td>{ transaction.drHeads }</td>
                                    <td>{ transaction.crHeads }</td>
                                    <td>{ transaction.transactionTypeNumber }</td>
                                    <td>{ transaction.transactionDetailDescription }</td>
                                    {
                                        currencies?.map((currency) => {
                                            var amountColor = 'black';
                                            if (transaction.currencyID == currency.currencyID) {
                                                amountColor = transaction.amount < 0 ? 'red' : 'green';
                                            }
                                            var transAmount = new Intl.NumberFormat('en-IR').format(transaction.amount.toString().replaceAll(',', ''));
                                            return (
                                                <td align="right" key={currency.currencyID} style={{ color: amountColor }}>
                                                    { transaction.currencyID == currency.currencyID ? transAmount : '0' }
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                    {
                        !transactionData.length && <tr><td colSpan={currencies.length+6}>No Record Found</td></tr>
                    }
                </tbody>
                <tfoot>
                    <tr style={{ fontWeight: 'bold' }}>
                        <td colSpan="6" align="right">{ t('total') }</td>
                        {
                            currencies.map((currency) => {
                                var totalColor = 'white';
                                if (currencyTotal[currency.currencyCode] > 0) {
                                    totalColor = '#A3F7A4';
                                } else if (currencyTotal[currency.currencyCode] < 0) {
                                    totalColor = '#FFADA5';
                                }
                                var totalAmount = new Intl.NumberFormat('en-IR').format(currencyTotal[currency.currencyCode].toString().replaceAll(',', ''));
                                return (<td align="right" key={currency.currencyID} style={{ backgroundColor: totalColor }}>
                                    { totalAmount }
                                    </td>
                                )
                            })
                        }
                    </tr>
                </tfoot>
            </table>
        </div>
    )
}

export default DailyCashReport
import { Button } from '@mui/material';
import useAxios from '../../hooks/useAxios';
import useLocalStorage from '../../hooks/useLocalStorage';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const PartyLedgerReport = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const startDate = searchParams.get('startDate');
    const endDate = searchParams.get('endDate');
    const bankAccountID = searchParams.get('bankAccountID');

    const [currencies, setCurrencies] = useState([]);
    const [currencyTotal, setCurrencyTotal] = useState(0);
    const [totalColor, setTotalColor] = useState('white');
    const [transactionData, setTransactionData] = useState([]);
    const [user, setUser] = useLocalStorage("user", null);
    const [listError, sendListRequest] = useAxios(setData);
    const [isShowTotal, setIsShowTotal] = useState(false);

    const { t, i18n } = useTranslation();

    function setData(data) {
        setCurrencyTotal(new Intl.NumberFormat('en-IR').format(data.data?.balance.toString().replaceAll(',', '')));
        setCurrencies(data.data?.currencies);
        setTransactionData(data.data?.transactions);
        if (data.data.balance > 0) {
            setTotalColor('#A3F7A4');
        } else if (data.data?.balance < 0) {
            setTotalColor('#FFADA5');
        }
    }

    const handlePrintPDF = () => {
        // const doc = new jsPDF();
        // autoTable(doc, { html: '#daily-cash-report' })
        // doc.save('dailyCashBook.pdf');
        window.print();
    }

    function fetchListData() {
        sendListRequest({
          url:`/bankAccount/${bankAccountID}/transactions?startDate=${startDate}&endDate=${endDate}`,
          method: 'GET',
          body: {},
          headers: { Accept: 'application/json', Authorization: 'Bearer ' + user.access_token }
        })
    }

    useEffect(() => {
        fetchListData();
        document.documentElement.lang = 'ur';
        document.documentElement.dir = 'rtl';
        i18n.changeLanguage('ur');
    },[]);
    
    return (
        <div className="report">
            <Button type="button" onClick={ handlePrintPDF }>Print</Button>
            <h3 style={{ textAlign: 'center' }}>{ startDate } { t('date_to') } { endDate } { t('upto') }</h3>
            <h4>{ t('ledger_name') } : {transactionData.length ? transactionData[0].accountTitle : '' }</h4>
            <table cellPadding="5" id="daily-cash-report" style={{ width: '100%', borderCollapse: 'collapse' }} border="1">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>{ t('date') }</th>
                        <th>{ t('number') }</th>
                        <th>{ t('description') }</th>
                        <th>{ t('amount') }</th>
                        <th>{ t('balance') }</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        transactionData.length && transactionData.map((transaction,index) => {
                            var amountColor = 'black';
                            amountColor = transaction.transactionAmount < 0 ? 'red' : 'green';
                            var balanceAmountColor = transaction.balance < 0 ? 'red' : 'green';
                            var transAmount = new Intl.NumberFormat('en-IR').format(transaction.transactionAmount.toString().replaceAll(',', ''));
                            return (
                                <tr key={transaction.transactionID}>
                                    <td>{ index+1} </td>
                                    <td>{ transaction.transactionDate }</td>
                                    <td>{ transaction.transactionTypeNumber }</td>
                                    <td>{ transaction.description }</td>
                                    <td align="right" style={{ color: amountColor, direction: 'ltr' }}>
                                        { transAmount }
                                    </td>
                                    <td align="right" style={{ color: balanceAmountColor, direction: 'ltr' }}>
                                        { transaction.balance }
                                    </td>
                                </tr>
                            )
                        })
                    }
                    {
                        !transactionData.length && (<tr><td colSpan={1+4}>No Record Found</td></tr>)
                    }
                </tbody>
                <tfoot>
                    <tr style={{ fontWeight: 'bold' }}>
                        <td colSpan="4" align="right">{ t('balance') }</td>
                        <td align="right" style={{ backgroundColor: totalColor, direction: 'ltr' }}>
                            { currencyTotal }
                        </td>
                        <td></td>
                    </tr>
                </tfoot>
            </table>
        </div>
    )
}

export default PartyLedgerReport
import { Box, Typography } from '@mui/material';
import React from 'react'
import MEDrawer from '../components/MEDrawer';
import { useAuth } from '../hooks/useAuth'

const Profile = () => {
    const auth = useAuth();
  return (
    <MEDrawer>
        <Box sx={{ width: '100%' }}>
        <Typography variant="h3" gutterBottom>
            { auth.user.user_info.name }.
        </Typography>
        <Typography variant="h4" gutterBottom>
            { auth.user.user_info.email }.
        </Typography>
        </Box>
    </MEDrawer>
  )
}

export default Profile

import React from 'react';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, FormHelperText, Checkbox } from '@mui/material'
import { useState, useEffect, useCallback } from 'react';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import MEDrawer from '../../components/MEDrawer';
import Toast from '../../components/Toast';
import useAxios from '../../hooks/useAxios';
import useLocalStorage from '../../hooks/useLocalStorage';
import useToast from '../../hooks/useToast';
import Avatar from '@mui/material/Avatar';
import { Card, CardContent, CardHeader } from '@mui/material';
import ListIcon from '@mui/icons-material/List';
import { blue, green, grey } from '@mui/material/colors';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';


const Party = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();

  const initialState = {partyTypeID: 1,  partyName: '', phoneNumber: '', address: '',description: ''};
  const colSet1 = [
    { field: 'partyID', headerName: t('id') , width: 50},
    { field: 'refNo', headerName: t('ref_no') , width: 150},
    { field: 'partyName', headerName: t('party_name') , width: 200},
    { field: 'phoneNumber', headerName: t('phone') + ' ' + t('number') , width: 200},
    { field: 'address', headerName: t('address') , width: 300},
  ];
  const colSet2 = [
    { field: 'description', headerName: t('description') , width: 300},
    { field: 'dateCreated', headerName: t('created_on') , width: 200},
    { field: 'Action', headerName: t('action') , width: 200, type: 'actions', getActions: (params) => [
      <GridActionsCellItem icon={<DeleteIcon />} onClick={handleDelete(params.id)} label={ t('delete') } />,
      <GridActionsCellItem icon={<EditIcon />} onClick={handleEdit(params.id)} label={ t('edit') } />
    ]}
  ];
  
  const [formValues, setFormValues] = useState(initialState);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [partyData, setPartyData] = useState([]);
  const [buttonText, setButtonText] = useState('save');
  const [editID, setEditID] = useState(0);
  const [isRequesting, setIsRequesting] = useState(false);
  const [partyTypes, setPartyTypes] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [columns, setColumns] = useState([]);

  const [listError, sendListRequest] = useAxios(setData);
  const [editError, sendEditRequest] = useAxios(setEditData);
  const [deleteError, sendDeleteRequest] = useAxios(checkDeleted);

  const [error, sendPostRequest] = useAxios(getSavedData);

  const [user, setUser] = useLocalStorage("user", null);
  
  const { open, setOpen, responseType, setResponseType, responseMessage, setResponseMessage, handleClose } = useToast();
  
  function setData(data) {
    var currenciesData = data.data.currencies;
    setCurrencies(currenciesData);
    var currencyObj = {};
    var aryCurrencies = [];
    currenciesData.map(currency => {
      var currencyKey = `currency_${currency.currencyID}`;
      currencyObj = {...currencyObj, [currencyKey] : ''};
      aryCurrencies.push({ field: 'balance' + currency.currencyCode, 'headerName' : t(currency.currencyCode), width: 150, valueGetter: (params) => params.row.balance.currenciesPayable[currency.currencyCode]['totalPayable']});
    });
    setColumns([...colSet1,...aryCurrencies,...colSet2]);

    setPartyData(data.data.parties);
    setPartyTypes(data.data.partyTypes);
  }

  function setEditData(data) {
    setFormValues(data.data.party);
    setIsRequesting(false);
  }

  function checkDeleted(data) {
    if (data.data.success === true) {
      setResponseType('success');
      setResponseMessage(data.data.message);
    } else {
      setResponseType('error');
      setResponseMessage(data.message);
    }
    setOpen(true);
    fetchListData();
    setIsRequesting(false);
  }

  const validate = (values) => {
    const errors = {};
    // if (isNaN(parseInt(values.partyTypeID))) {
    //   errors.partyTypeID = t('party') + ' ' + t('type') + ' ' + t('is_required');
    // }
    if (!values.partyName.length) {
      errors.partyName = t('party') + ' ' + t('name') + ' ' + t('is_required');
    }
    
    return errors;
  }

  function getSavedData(data) {
    if (data.status === 200) {
      setResponseType('success');
      if (!data.data.success) {
        setResponseType('error'); 
      }
      setResponseMessage(data.data.message);
      responseType === 'success' ? setFormValues(initialState) : setFormValues(formValues);
    } else {
      setResponseType('error');
      setResponseMessage(data.data.message);
    }
    setOpen(true);
    fetchListData();
    setButtonText('save');
    setEditID(0);
    setIsRequesting(false);
  }

  const handleEdit = useCallback(
    (id) => () => {
      // navigate(`/currency/${id}`);
      if (isRequesting) return;
      setIsRequesting(true);
      setButtonText('update');
      setEditID(id);
      sendEditRequest({
        url: '/party/'+id,
        method: 'GET',
        body: null,
        headers: { Accept: 'application/json', Authorization: 'Bearer ' + user.access_token }
      });
    },[]
  );


  const handleDelete = useCallback(
    (id) => () => {
      if (window.confirm('Are you sure you want to delete this record?')) {
        if (isRequesting) return;
        setIsRequesting(true);
        sendDeleteRequest({
          url: '/party/'+id,
          method: 'DELETE',
          body: {},
          headers: { Accept: 'application/json', Authorization: 'Bearer ' + user.access_token }
        });
      }
    },[]
  );

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({...formValues, [name] : value});
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  const handlePartyLedger = (params, event, details) => {
    navigate('/party/'+params.id);
  }

  const handleCancel = () => {
    setFormValues(initialState);
    setButtonText('save');
    setEditID(0);
    setIsSubmit(false);
    setFormErrors({});
  }

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      if (isRequesting) return;
      setIsRequesting(true);
      if (!isNaN(parseInt(editID)) && editID > 0) {
        sendPostRequest({
          url: '/party/'+editID,
          method: 'PUT',
          body: formValues,
          headers: { Accept: 'application/json', Authorization: 'Bearer ' + user.access_token }
        });
      } else {
        sendPostRequest({
          url: '/party',
          method: 'POST',
          body: formValues,
          headers: { Accept: 'application/json', Authorization: 'Bearer ' + user.access_token }
        });
      }      
    }
  },[formErrors]);

  useEffect(() => {
    fetchListData();
  },[]);

  function fetchListData() {
    sendListRequest({
      url: '/party',
      method: 'GET',
      body: {},
      headers: { Accept: 'application/json', Authorization: 'Bearer ' + user.access_token }
    })
  }

  return (
    <MEDrawer>
        <Box>
          <Toast open={open} handleClose={handleClose} responseType={responseType} responseMessage={responseMessage} />
          <div>
          <Card sx={{ bgcolor: blue[50]}}>
                  <CardHeader avatar={ <Avatar sx={{ bgcolor: green[500] }}><PeopleAltIcon  /></Avatar> } title= { t('party') + ' ' + t('form')  } />
                  <CardContent sx={{ width: '100%' }}>
                      {/* <FormControl size="small" sx={{ ml: 1, minWidth: 200  }} error={formErrors?.partyTypeID && formErrors.partyTypeID.length > 0}>
                          <InputLabel>  { t('party_type') } : </InputLabel>
                          <Select label={ t('party_type') } onChange={handleChange} name="partyTypeID" value={formValues.partyTypeID}>
                              <MenuItem value="">Select Party Type</MenuItem>
                              {
                                  partyTypes?.map((value) => {
                                      return(<MenuItem key={value.partyTypeID} value={value.partyTypeID}>{value.partyTypeName}</MenuItem>)
                                  })
                              }
                          </Select>
                          <FormHelperText>{formErrors.partyTypeID}</FormHelperText>
                      </FormControl> */}
                      <input type="hidden" name="partyTypeID" value={formValues.partyTypeID} />
                      <TextField sx={{ ml: 1 }} helperText={formErrors.partyName} name="partyName" required variant="outlined" label={ t('party_name') }  value={formValues.partyName} onChange={handleChange} size="small" error={formErrors?.partyName && formErrors.partyName.length > 0} />
                      <TextField sx={{ ml: 1 }} name="phoneNumber" variant="outlined" label={ t('phone') + ' '+ t('number') }  value={(formValues.phoneNumber === null ? '' : formValues.phoneNumber)} onChange={handleChange} size="small" />
                      <TextField sx={{ ml: 1 }} name="address" variant="outlined" label={ t('address') } value={(formValues.address === null ? '' : formValues.address)} onChange={handleChange} size="small" />
                      <br />
                      <br />
                      {
                        editID === 0 && currencies.map(currency => {
                          return <TextField sx={{ ml: 1 }} helperText={t(currency.currencyName)} name={'currency_'+currency.currencyID} key={'currency_'+currency.currencyID} variant="outlined" label={ t('opening_balance') } onChange={handleChange} size="small" />
                        })
                      }
                      {
                        editID === 1 && <><br /><br /></>
                      }
                      
                      <TextField fullWidth sx={{ ml: 1 }} name="description" variant="outlined" label={ t('description') } value={(formValues.description === null ? '' : formValues.description)} onChange={handleChange} size="small" />
                      <br />
                      <br />
                      <Button sx={{ ml: 1 }} variant="contained" onClick={handleSubmit}>{ t(buttonText) }</Button>
                      <Button sx={{ ml: 2 }} variant="contained" color="secondary" onClick={handleCancel}>{ t('cancel') }</Button>
                  </CardContent>
              </Card>
          </div>
        </Box>
        <br />
        <Card sx={{ bgcolor: grey[100]}}>
          <CardHeader 
            avatar={
              <Avatar sx={{ bgcolor: green[500] }}><ListIcon  /></Avatar>
            }
            title={ t('party') + ' ' + t('list') } />
          <CardContent sx={{ width: '100%' }}>
            <DataGrid 
              density="compact"
              autoHeight
              rows={partyData}
              columns={columns}
              pageSize={50}
              rowsPerPageOptions={[50]}
              checkboxSelection
              onRowDoubleClick={handlePartyLedger}
              getRowId={row => row.partyID}
              getCellClassName={(params) => {
                if (params.value && params.value.toString().substring(0,1) === '-') {
                  return 'minus';
                }
                return '';
              }}
            />
          </CardContent>
        </Card>
    </MEDrawer>
  )
}

export default Party;

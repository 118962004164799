import { Button } from '@mui/material';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import useAxios from '../../hooks/useAxios';
import useLocalStorage from '../../hooks/useLocalStorage';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const PartyLedgerReport = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const startDate = searchParams.get('startDate');
    const endDate = searchParams.get('endDate');
    const partyID = searchParams.get('partyID');

    const [currencies, setCurrencies] = useState([]);
    const [currencyTotal, setCurrencyTotal] = useState({});
    const [transactionData, setTransactionData] = useState([]);
    const [user, setUser] = useLocalStorage("user", null);
    const [listError, sendListRequest] = useAxios(setData);
    const [isShowTotal, setIsShowTotal] = useState(false);

    const { t, i18n } = useTranslation();

    function setData(data) {
        setCurrencyTotal(data.data.balance);
        setCurrencies(data.data.currencies);
        setTransactionData(data.data.transactions);
    }

    const handlePrintPDF = () => {
        // const doc = new jsPDF();
        // autoTable(doc, { html: '#daily-cash-report' })
        // doc.save('dailyCashBook.pdf');
        window.print();
    }

    function fetchListData() {
        sendListRequest({
          url:`/party/${partyID}/transactions?startDate=${startDate}&endDate=${endDate}`,
          method: 'GET',
          body: {},
          headers: { Accept: 'application/json', Authorization: 'Bearer ' + user.access_token }
        })
    }

    useEffect(() => {
        fetchListData();
        document.documentElement.lang = 'ur';
        document.documentElement.dir = 'rtl';
        i18n.changeLanguage('ur');
    },[]);
    
    return (
        <div className="report">
            <Button type="button" onClick={ handlePrintPDF }>Print</Button>
            <h3 style={{ textAlign: 'center' }}>{ startDate } { t('date_to') } { endDate } { t('upto') }</h3>
            <h4>{ t('ledger_name') } : {transactionData.length ? (transactionData[0].partyName + (transactionData[0].refNo ? ('__( ' + transactionData[0].refNo + ' )') : '')) : '' }</h4>
            <table cellPadding="5" id="daily-cash-report" style={{ width: '100%', borderCollapse: 'collapse' }} border="1">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>{ t('date') }</th>
                        <th>{ t('number') }</th>
                        <th>{ t('description') }</th>
                        {
                            currencies?.map((currency) => <><th key={currency.currencyID}>{ t(currency.currencyName) }</th><th key={'balance_' + currency.currencyID}>{ t('balance') }</th></>)
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        transactionData.length && transactionData.map((transaction,index) => {
                            return (
                                <tr key={transaction.transactionID}>
                                    <td>{ index+1} </td>
                                    <td>{ transaction.transactionDate }</td>
                                    <td>{ transaction.transactionTypeNumber }</td>
                                    <td>{ transaction.description }</td>
                                    {
                                        currencies?.map((currency) => {
                                            var amountColor = 'black';
                                            if (transaction.description === 'Previous Balance') {
                                                var amount = transaction.currenciesBalance[currency.currencyCode];
                                                amountColor = amount < 0 ? 'red' : 'green';
                                                var transAmount = new Intl.NumberFormat('en-IR').format(amount.toString().replaceAll(',', ''));
                                                return (
                                                    <>
                                                        <td align="right" key={currency.currencyID} style={{color: amountColor, direction: 'ltr'}}>
                                                            {transAmount}
                                                        </td>
                                                        <td align="right" style={{ direction: 'ltr' }}>0</td>
                                                    </>
                                                )
                                            } else {
                                                if (transaction.currencyID == currency.currencyID) {
                                                    amountColor = transaction.transactionAmount < 0 ? 'red' : 'green';
                                                }
                                                var transAmount = new Intl.NumberFormat('en-IR').format(transaction.transactionAmount.toString().replaceAll(',', ''));
                                                
                                                amountColor = transaction.balances[currency.currencyCode] < 0 ? 'red' : 'green';
                                                if (transaction.balances[currency.currencyCode] === 0) {
                                                    amountColor = 'black';
                                                }

                                                return (
                                                    <>
                                                        <td align="right" key={currency.currencyID} style={{color: amountColor, direction: 'ltr'}}>
                                                            {transaction.currencyID == currency.currencyID ? transAmount : '0'}
                                                        </td>
                                                        <td align="right" style={{ color: amountColor, direction: 'ltr' }}>{ transaction.balances[currency.currencyCode] }</td>
                                                    </>
                                                )
                                            }
                                            
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                    {
                        !transactionData.length && <tr><td colSpan={currencies.length+4}>No Record Found</td></tr>
                    }
                </tbody>
                <tfoot>
                    <tr style={{ fontWeight: 'bold' }}>
                        <td colSpan="4" align="right">{ t('balance') }</td>
                        {
                            currencies.map((currency) => {
                                var totalColor = 'white';
                                if (currencyTotal[currency.currencyCode].balance > 0) {
                                    totalColor = '#A3F7A4';
                                } else if (currencyTotal[currency.currencyCode].balance < 0) {
                                    totalColor = '#FFADA5';
                                }
                                var totalAmount = new Intl.NumberFormat('en-IR').format(currencyTotal[currency.currencyCode].balance.toString().replaceAll(',', ''));
                                return (
                                    <>
                                        <td align="right" key={currency.currencyID} style={{ backgroundColor: totalColor, direction: 'ltr' }}>
                                            { totalAmount }
                                        </td>
                                        <td></td>
                                    </>
                                )
                            })
                        }
                    </tr>
                </tfoot>
            </table>
        </div>
    )
}

export default PartyLedgerReport
/* eslint-disable no-template-curly-in-string */
import React from 'react'
import { Link } from 'react-router-dom';
import MEDrawer from '../../components/MEDrawer';
import { useAuth } from '../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Card, CardActions, CardContent, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import PaidIcon from '@mui/icons-material/Paid';
import PaymentsIcon from '@mui/icons-material/Payments';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Receipt from '@mui/icons-material/Receipt';
import { purple, indigo, teal, cyan } from '@mui/material/colors';
import { useState } from 'react';
import moment from 'moment';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { useEffect } from 'react';
import useAxios from '../../hooks/useAxios';
import useLocalStorage from '../../hooks/useLocalStorage';

const todayDate = moment().format("YYYY-MM-DD");

const Dashboard = () => {

  const partyLedgerInitialValues = { 'partyID': null, partyLedgerReportStartDate: todayDate, partyLedgerReportEndDate: todayDate };
  const bankLedgerInitialValues = { 'bankAccountID': null, bankLedgerReportStartDate: todayDate, bankLedgerReportEndDate: todayDate };
  const [dailyCashReportDate, setDailyCashReportDate] = useState(todayDate);
  const [partyLedgerValues, setPartyLedgerValues] = useState(partyLedgerInitialValues);
  const [bankLedgerValues, setBankLedgerValues] = useState(bankLedgerInitialValues);
  const [parties, setParties] = useState([]);
  const [bankAccounts, setBankAccounts] = useState([]);

  const [partyListError, sendPartyListRequest] = useAxios(setPartyData);
  const [bankAccountListError, sendbankAccountListRequest] = useAxios(setBankAccountData);
  const [user, setUser] = useLocalStorage("user", null);

  const { t } = useTranslation();
  const auth = useAuth();

  function setPartyData(data) {
    setParties(data.data.parties);
  }

  function setBankAccountData(data) {
    setBankAccounts(data.data.bankAccount);
  }

  const partyLedgerReportDataChanged = (e) => {
    if (e.target === undefined) {
      setPartyLedgerValues({ ...partyLedgerValues, partyID: e.partyID })
    } else {
      const { name, value } = e.target;
      setPartyLedgerValues({ ...partyLedgerValues, [name]: value })
    }
  }

  const bankLedgerReportDataChanged = (e) => {
    const { name, value } = e.target;
    setBankLedgerValues({ ...bankLedgerValues, [name]: value })
  }

  const dailyCashReportDateChanged = (e) => {
    setDailyCashReportDate(e.target.value);
  }

  function getParties() {
    sendPartyListRequest(
      {
        url: '/party',
        method: 'GET',
        body: {},
        headers: { Accept: 'application/json', Authorization: 'Bearer ' + user.access_token }
      }
    )
  }

  function getBankAccounts() {
    sendbankAccountListRequest(
      {
        url: '/bankAccount',
        method: 'GET',
        body: {},
        headers: { Accept: 'application/json', Authorization: 'Bearer ' + user.access_token }
      }
    )
  }

  useEffect(() => {
    getParties();
    getBankAccounts();
  }, []);

  return (
    <MEDrawer>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {auth.user !== null &&
          <>
            <Grid item>
              <Card sx={{ boxShadow: 3, bgcolor: indigo[50] }}>
                <CardContent>
                  <Divider>
                    <PeopleAltIcon sx={{ fontSize: 40 }} color="success" />
                  </Divider>
                  <Typography variant="h5" component="div">
                    {t('party_ledger')}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {t('select_party_dates_and_click_print')}
                  </Typography>
                  <TextField type="date" sx={{ mt: 2, minWidth: 200 }} name="partyLedgerReportStartDate" value={partyLedgerValues.partyLedgerReportStartDate} variant="outlined" label={t('start_date')} onChange={partyLedgerReportDataChanged} size="small" />
                  <br />
                  <TextField type="date" sx={{ mt: 2, minWidth: 200 }} name="partyLedgerReportEndDate" value={partyLedgerValues.partyLedgerReportEndDate} variant="outlined" label={t('end_date')} onChange={partyLedgerReportDataChanged} size="small" />
                  <br />
                  <FormControl size="small" sx={{ mt: 2, minWidth: 200 }}>
                    {/* <InputLabel> {t('party')} </InputLabel> */}

                    <Autocomplete
                      options={parties}
                      getOptionLabel={(option) => (option.partyName + ' - ' + (option.refNo ?? '') + ' ( ' + option.address + ') ')}
                      onChange={(a, b) => partyLedgerReportDataChanged(b)}
                      renderInput={(params) => <TextField {...params} label={t('party')} variant="outlined" />}
                    />

                    {/* <Select label={t('party')} onChange={partyLedgerReportDataChanged} name="partyID" value={partyLedgerValues.partyID}>
                      <MenuItem key={-1} value=""> {t('select_party')} </MenuItem>
                      {
                        parties?.map((party) => <MenuItem key={party.partyID} value={party.partyID}>{party.partyName}</MenuItem>)
                      }
                    </Select> */}
                  </FormControl>
                </CardContent>
                <CardActions sx={{ justifyContent: 'center' }}>
                  <Link to={'/reports/partyLedgerReport?startDate=' + partyLedgerValues.partyLedgerReportStartDate + '&endDate=' + partyLedgerValues.partyLedgerReportEndDate + '&partyID=' + partyLedgerValues.partyID} target="_blank">{t('print')}</Link>
                </CardActions>
              </Card>
            </Grid>
            <Grid item>
              <Card sx={{ boxShadow: 3, bgcolor: indigo[50] }}>
                <CardContent>
                  <Divider>
                    <PaymentsIcon sx={{ fontSize: 40 }} color="success" />
                  </Divider>
                  <Typography variant="h5" component="div">
                    {t('daily_cash_book')} {t('report')}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {t('select_date_and_click_print')}
                  </Typography>
                  <TextField type="date" sx={{ mt: 2, minWidth: 200 }} value={dailyCashReportDate} variant="outlined" label={t('date')} onChange={dailyCashReportDateChanged} size="small" />

                </CardContent>
                <CardActions sx={{ justifyContent: 'center' }}>
                  <Link to={'/reports/dailyCashBook?reportDate=' + dailyCashReportDate} target="_blank">{t('print')}</Link>
                </CardActions>
              </Card>
            </Grid>

            <Grid item>
              <Card sx={{ boxShadow: 3, bgcolor: purple[50] }}>
                <CardContent>
                  <Divider>
                    <PaidIcon sx={{ fontSize: 40 }} color="success" />
                  </Divider>
                  <Typography variant="h5" component="div">
                    {t('parties_balance')}  {t('report')}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {t('parties_balance')}
                  </Typography>
                  <Typography variant="body2">
                    {t('click_print')}
                  </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: 'center' }}>
                  <Link to="/reports/partyBalanceReport">{t('print')}</Link>
                </CardActions>
              </Card>
            </Grid>


            <Grid item>
              <Card sx={{ boxShadow: 3, bgcolor: indigo[50] }}>
                <CardContent>
                  <Divider>
                    <AccountBalanceIcon sx={{ fontSize: 40 }} color="success" />
                  </Divider>
                  <Typography variant="h5" component="div">
                    {t('bank_account_ledger')}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {t('select_bank_dates_and_click_print')}
                  </Typography>
                  <TextField type="date" sx={{ mt: 2, minWidth: 200 }} name="bankLedgerReportStartDate" value={bankLedgerValues.bankLedgerReportStartDate} variant="outlined" label={t('start_date')} onChange={bankLedgerReportDataChanged} size="small" />
                  <br />
                  <TextField type="date" sx={{ mt: 2, minWidth: 200 }} name="bankLedgerReportEndDate" value={bankLedgerValues.bankLedgerReportEndDate} variant="outlined" label={t('end_date')} onChange={bankLedgerReportDataChanged} size="small" />
                  <br />
                  <FormControl size="small" sx={{ mt: 2, minWidth: 200 }}>
                    <InputLabel> {t('bank_account')} </InputLabel>
                    <Select label={t('bank_account')} onChange={bankLedgerReportDataChanged} name="bankAccountID" value={bankLedgerValues.bankAccountID}>
                      <MenuItem key={-1} value=""> {t('select_bank_account')} </MenuItem>
                      {
                        bankAccounts?.map((bankAccount) => <MenuItem key={bankAccount.bankAccountID} value={bankAccount.bankAccountID}>{bankAccount.bankName} ({bankAccount.accountTitle})</MenuItem>)
                      }
                    </Select>
                  </FormControl>
                </CardContent>
                <CardActions sx={{ justifyContent: 'center' }}>
                  <Link to={'/reports/bankLedgerReport?startDate=' + bankLedgerValues.bankLedgerReportStartDate + '&endDate=' + bankLedgerValues.bankLedgerReportEndDate + '&bankAccountID=' + bankLedgerValues.bankAccountID} target="_blank">{t('print')}</Link>
                </CardActions>
              </Card>
            </Grid>
          </>
        }
      </Grid>
    </MEDrawer>
  )
}

export default Dashboard;
import React from 'react'
import { Link } from 'react-router-dom';
import MEDrawer from '../components/MEDrawer';
import { useAuth } from '../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { Card, CardActions, CardContent, Divider, Grid, Typography } from '@mui/material';
import PaidIcon from '@mui/icons-material/Paid';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Receipt from '@mui/icons-material/Receipt';
import { purple, indigo, teal, cyan } from '@mui/material/colors';

const Dashboard = () => {
  const { t } = useTranslation();

  const auth = useAuth();

  return (
    <MEDrawer>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      { auth.user === null ? (
          <Grid item>
          <Card>
            <CardContent>
              <Typography variant="h5" component="div">
              { t('login') }
              </Typography>
            </CardContent>
            <CardActions>
            <Link to="/login">{ t('click_here') }</Link>
            </CardActions>
          </Card>
        </Grid>
        ) : (
          <>
          <Grid item>
            <Card sx={{ boxShadow: 3, bgcolor: purple[50] }}>
                      <CardContent>
                        <Divider>
                          <PaidIcon sx={{ fontSize: 40 }} color="success" />
                        </Divider>
                        <Typography variant="h5" component="div">
                        { t('currency') }
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                          { t('create') } - { t('read') } - { t('update') } - { t('delete') }
                        </Typography>
                        <Typography variant="body2">
                         { t('currencies') } {t('list_and_form_of')}
                        </Typography>
                      </CardContent>
                      <CardActions sx={{ justifyContent: 'center'}}>
                      <Link to="/currency">{ t('click_here') }</Link>
                      </CardActions>
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card sx={{ boxShadow: 3, bgcolor: indigo[50] }}>
                      <CardContent>
                        <Divider>
                          <PeopleAltIcon sx={{ fontSize: 40 }} color="success" />
                        </Divider>
                        <Typography variant="h5" component="div">
                        { t('party') }
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        { t('create') } - { t('read') } - { t('update') } - { t('delete') }
                        </Typography>
                        <Typography variant="body2">
                           { t('parties') } {t('list_and_form_of')}
                        </Typography>
                      </CardContent>
                      <CardActions sx={{ justifyContent: 'center'}}>
                        <Link to="/party">{ t('click_here') }</Link>
                      </CardActions>
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card sx={{ boxShadow: 3, bgcolor: teal[50] }}>
                      <CardContent>
                        <Divider>
                          <AccountBalanceIcon sx={{ fontSize: 40 }} color="success" />
                        </Divider>
                        <Typography variant="h5" component="div">
                        { t('bank_account') }
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        { t('create') } - { t('read') } - { t('update') } - { t('delete') }
                        </Typography>
                        <Typography variant="body2">
                         { t('bank_account') } {t('list_and_form_of')}
                        </Typography>
                      </CardContent>
                      <CardActions sx={{ justifyContent: 'center'}}>
                        <Link to="/bankAccount">{ t('click_here') }</Link>
                      </CardActions>
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card sx={{ boxShadow: 3, bgcolor: cyan[50] }}>
                      <CardContent>
                        <Divider>
                            <Receipt sx={{ fontSize: 40 }} color="success" />
                        </Divider>
                        <Typography variant="h5" component="div">
                        { t('payment_receipt') }
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        { t('create') } - { t('read') } - { t('update') } - { t('delete') }
                        </Typography>
                        <Typography variant="body2">
                        { t('payment') }  {t('list_and_form_of')}
                        </Typography>
                      </CardContent>
                      <CardActions sx={{ justifyContent: 'center'}}>
                        <Link to="/paymentReceipt">{ t('click_here') }</Link>
                      </CardActions>
                    </Card>
                  </Grid>

                  <Grid item>
                    <Card sx={{ boxShadow: 3, bgcolor: cyan[50] }}>
                      <CardContent>
                        <Divider>
                            <Receipt sx={{ fontSize: 40 }} color="success" />
                        </Divider>
                        <Typography variant="h5" component="div">
                        { t('reports') }
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        { t('create') } - { t('read') } - { t('update') } - { t('delete') }
                        </Typography>
                        <Typography variant="body2">
                        { t('view') }  {t('reports')}
                        </Typography>
                      </CardContent>
                      <CardActions sx={{ justifyContent: 'center'}}>
                        <Link to="/reports">{ t('click_here') }</Link>
                      </CardActions>
                    </Card>
                  </Grid>
          </>
        ) 
      }
        
      </Grid>
    </MEDrawer>
  )
}

export default Dashboard;
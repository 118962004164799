import axios from 'axios'
import { useState } from 'react'

// axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.baseURL = 'http://money-exchanger.localhost/api';
if (window.location.href.indexOf('auh313.com.pk') > 0) {
  axios.defaults.baseURL = 'https://admin.auh313.com.pk/api';
}

console.log(window.location.href.indexOf('khanmart.com.pk'));
const useAxios = (cbAction) => {
  const [error, setError] = useState('');

  const fetchData = ({ url, method, body = null, headers = null }) => {
    axios({
      url: url,
      method: method,
      headers: headers,
      data: body
    })
      .then(function (response) {
        cbAction(response);
      })
      .catch(function (error) {
        cbAction(error.response);
      })
  }

  return [error, fetchData];
}

export default useAxios;
import PaidIcon from '@mui/icons-material/Paid';
import { Avatar, Card, CardContent, CardHeader } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom'
import MEDrawer from '../../components/MEDrawer';
import useAxios from '../../hooks/useAxios';
import { blue, green, grey } from '@mui/material/colors';
import useLocalStorage from '../../hooks/useLocalStorage';

const BankAccountLedger = () => {
    const { t } = useTranslation();

    const { id } = useParams();
    const [bankAccountLedger, setBankAccountLedger] = useState([]);
    const [user, setUser] = useLocalStorage("user", null);
    const [bankAccountInfo, setBankAccountInfo] = useState({});
    const [listError, sendListRequest] = useAxios(setData);

    function setData(data) {
        console.log(data);
        setBankAccountInfo(data.data.party);
        setBankAccountLedger(data.data.ledger);
    }

    useEffect(() => {
        sendListRequest({
            url: `/bankAccount/${id}`,
            method: 'GET',
            body: {},
            headers: { Accept: 'application/json', Authorization: 'Bearer ' + user.access_token }
        })
    },[]);

    const columns = [
        { field: 'id', headerName: t('id') , width: 50},
        { field: 'transactionDate', headerName: t('date') , width: 120},
        { field: 'headName', headerName: t('account') , width: 200},
        { field: 'debit', headerName: t('debit') , width: 150},
        { field: 'credit', headerName: t('credit') , width: 150},       
        { field: 'balance', headerName: t('balance') , width: 150},
        { field: 'description', headerName: t('description') , width: 300},
    ];

    return (
        <MEDrawer>
            <Card sx={{ bgcolor: grey[100]}}>
                <CardHeader avatar={<Avatar sx={{ bgcolor: green[500] }}><PaidIcon  /></Avatar>} title={t('bank_account')} />
                <CardContent sx={{ width: '100%' }}>
                    <DataGrid 
                        density="compact" 
                        autoHeight 
                        rows={bankAccountLedger} 
                        columns={columns} 
                        pageSize={50} 
                        rowsPerPageOptions={[50]} 
                        getCellClassName={(params) => {
                            var classNames = '';
                            if (params.value && params.value.toString().substring(0, 1) === '-') {
                                classNames = 'minus';
                            }
                            if (params.value && params.value.toString().substring(0, 5) === 'Total') {
                                classNames += ' total-row';
                            }
                            return classNames;
                        }}
                    />
                </CardContent>
            </Card>
        </MEDrawer>
    )
}

export default BankAccountLedger
